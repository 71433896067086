import * as React from "react";
import CallToAction from "../components/CallToAction/CallToAction";
import Layout from "../components/Layout/Layout";
import Companies from "../components/Companies/Companies";
import Features from "../components/Features/Features";
import Masthead from "../components/Masthead/Masthead";
import Pricing2 from "../components/Pricing2/Pricing2";
import Service from "../components/Service/Service";
import TextMedia from "../components/TextMedia/TextMedia";

const IndexPage = () => {
  return (
    <Layout>
      <Masthead overlay>
        <h1>T Cards Online</h1>
        <h2>The visual planner making work easier & processes better</h2>
      </Masthead>
      <CallToAction />
      <TextMedia
        title={
          <>
            ...with productivity <em>boosting</em> features...
          </>
        }
      />
      <Features />
      <Service />
      <Companies />
      <Pricing2 showAdvanced />
    </Layout>
  );
};

export default IndexPage;
